// https://vuex.vuejs.org/en/actions.html

import userService from "@/service/userService";

const actions = {
    async initApp(context) {
        context.commit('setUser', userService.getUser());
    },
    saveLanguage({commit}, payload) {
        commit('onLanguageChanged', payload);
    },
    setOffline({commit}, isOffline) {
        commit('setOffline', isOffline)
    }
}

export default actions
