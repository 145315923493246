import axios from "axios";
import userService from "./userService";
import router from "../router";
import store from "../store";
import i18n from "@/i18n";

export default() => {

    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };
    const token = userService.getToken();
    if(token != null) {
        headers['Authorization'] = `Bearer ${token}`
    }

    let axiosInstance = axios.create({
        baseURL: process.env.API_BASE_URL,
        withCredentials: false,
        headers
    });

    axiosInstance.interceptors.response.use(
        response => {
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                return Promise.resolve(response);
            } else {
                return Promise.reject(response);
            }
        }, error => {
            if (error.response && error.response.status) {
                switch (error.response.status) {
                    case 400:
                        //do something
                        break;

                    case 401:
                        userService.logout(store.state, `app.logoutReason.${error.response.status}`)
                        router.replace({
                            path: "/login",
                            query: {redirect: router.currentRoute.fullPath}
                        });
                        break;
                    case 403:
                        store.commit('snackbar/onNotification', {
                            message: i18n.t('app.error.permission'),
                            color: 'error'
                        }, {root: true});
                        break;
                    case 502:
                        setTimeout(() => {
                            router.replace({
                                path: "/login",
                                query: {
                                    redirect: router.currentRoute.fullPath
                                }
                            });
                        }, 1000);
                }
                return Promise.reject(error.response);
            }
        }
    );

    return axiosInstance
}
