<template>
  <v-dialog v-model="dialog" max-width="500px" @keydown.esc="close">
    <v-form v-model="valid" ref="form" @submit.prevent="save">
      <v-card>
        <v-card-title>{{ $t('valuelist.create.title') }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    v-model="valuelist.name"
                    :label="$t('table.field.name')"
                    :rules="[rules.required]"
                    autofocus
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="blue darken-1" text @click="close">{{ $t('app.btn.cancel') }}</v-btn>
          <v-btn color="blue darken-1" text type="submit" :disabled="!valid">{{ $t('app.btn.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {v4 as uuidv4} from 'uuid';
import {mapActions, mapMutations} from "vuex";
export default {
  name: "AddDialog",
  data() {
    return {
      dialog: false,
      valuelist: {
        uuid: null,
        name: null,
        timestamp: null,
        version: 1
      },
      defaultValuelist: {
        uuid: null,
        name: null,
        timestamp: null,
        version: 1
      },
      valid: false,
      rules: {
        required: value => !!value || this.$t('dialog.rules.required'),
      }
    }
  },
  methods: {
    open() {
      Object.assign(this.valuelist, this.defaultValuelist);
      this.dialog = true;
    },
    close() {
      this.dialog = false;

      this.$nextTick(() => {
        Object.assign(this.valuelist, this.defaultValuelist);
      });
    },
    async save() {
      this.setLoading(true);
      this.valuelist.uuid = uuidv4();
      const uuid = await this.createValueList(this.valuelist);
      if(uuid) {
        this.dialog = false;
        await this.$router.push(`/valuelistManagement/${uuid}`)
      } else {
        this.onNotification({message: this.$t('dialog.rules.unique'), color: 'error'});
      }
    },
    ...mapActions({
      createValueList: 'valuelist/createValueList',
    }),
    ...mapMutations({
      setLoading: 'setLoading',
      onNotification: 'snackbar/onNotification',
    }),
  }
}
</script>

<style scoped>

</style>