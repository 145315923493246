import api from './api'
import apiEndpoints from "@/constants/api/apiEndpoints";

export default {
    //TODO check backend, modify
    async getEmailAddressesFromApi() {
        return api().get(`${apiEndpoints.emailAddress}`).catch(err => {
            console.error(err);
            return err;
        });
    },
    async createEmailAddress({address, languageCode}) {
        return api().post(apiEndpoints.emailAddress, {address, languageCode}).catch(err => {
            console.error(err);
            return err;
        });
    },
    async updateEmailAddress({uuid, address, languageCode}) {
        return api().put(`${apiEndpoints.emailAddress}/${uuid}`,{address, languageCode}).catch(err => {
            console.error(err);
            return err;
        });
    },
    async deleteEmailAddress(uuid) {
        return api().delete(`${apiEndpoints.emailAddress}/${uuid}`).catch(err => {
            console.error(err);
            return err;
        });
    },
}
