/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

// Routes
import paths from './paths'
import store from "@/store";
import userService from "@/service/userService";

function route(path, view, name, comp, meta) {
    return {
        name: name || view,
        meta,
        path,
        component: comp ? comp : (resolve) => import(
            `@/views/${view}.vue`
            ).then(resolve)
    }
}

Vue.use(Router)

// Create a new router
const router = new Router({
    mode: 'history',
    routes: paths.map(path => route(path.path, path.view, path.name, path.comp, path.meta)).concat([
        {path: '*', redirect: '/'}
    ]),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        if (to.hash) {
            return {selector: to.hash}
        }
        return {x: 0, y: 0}
    }
})
router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const {authorize} = to.meta;

    if (authorize) {
        const authorized = store.getters.hasAnyRole(authorize)
        if (!authorized) {
            //only show a forbidden reason in case the user really has roles
            const reason = store.state.roles.length ? 'app.logoutReason.403' : undefined;
            // not logged in so redirect to login page with the return url
            userService.logout(store.state, reason)
           return next({path: '/login', query: {redirect: to.fullPath}});
        }
    }
    if (to.name !== 'Login' && !store.state.authenticated) {
        return next({path: '/login', query: {redirect: to.fullPath}});
    }

    return next();
})

Vue.use(Meta)

export default router
