import { set } from '@/utils/vuex'

export default {
    setEmailAddresses: set('emailAddresses'),

    addEmailAddress(state, emailAddress) {
        state.emailAddresses.push(emailAddress);
    },
    replaceEmailAddress(state, emailAddress){
        const index = state.emailAddresses.findIndex(a => a.uuid === emailAddress.uuid);
        state.emailAddresses.splice(index, 1, emailAddress);
    },
    removeEmailAddress(state, emailAddress) {
        const index = state.emailAddresses.findIndex(a => a.uuid === emailAddress.uuid);
        state.emailAddresses.splice(index, 1);
    },
    clearState(state) {
        state.emailAddresses = [];
    },
}
