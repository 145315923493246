<template>
    <v-app>
        <core-toolbar />

        <core-drawer v-if="authenticated"/>

        <core-view/>

        <core-snack-bar/>
    </v-app>
</template>

<script>
import {mapMutations} from "vuex";

export default {
    name: 'VERSMasterDataManagment',

    computed: {
        authenticated: {
            get() {
                return this.$store.state.authenticated;
            },
            set(val) {
                this.setAuthenticated(val)
            }
        }
    },

    beforeDestroy() {
        this.setLoading(false);
    },

    methods: {
        ...mapMutations(['setAuthenticated','setLoading'])
    }
}
</script>