import api from "@/service/api";
import apiEndpoints from "@/constants/api/apiEndpoints";

export default {

    //api
    async getXsdListFromApi() {
        return await api().get(`${apiEndpoints.xsd}`).catch(err=>err);
    },
    async getXsdContent(uuid) {
        return await api().get(`${apiEndpoints.xsd}/${uuid}/content`).catch(err=>err);
    },
    async uploadXsd(xsd) {
        return await api().post(`${apiEndpoints.xsd}`, xsd).catch(err=>err);
    },
    async downloadXsd(uuid) {
        return await api().get(`${apiEndpoints.xsd}/${uuid}/download`, {responseType: "blob"}).catch(err=>err);
    },
    async publishXsd(uuid) {
        return await api().post(`${apiEndpoints.xsd}/${uuid}/publish`).catch(err=>err);
    },
    async deleteXsd(uuid) {
        return await api().delete(`${apiEndpoints.xsd}/${uuid}`).catch(err=>err);
    },
    async getXsdErrors(uuid) {
        return await api().get(`${apiEndpoints.xsd}/${uuid}/errors`).catch(err=>err);
    },
}