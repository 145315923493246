<template>
  <div></div>
</template>

<script>
import {mapActions, mapState, mapGetters} from "vuex";

export default {
  name: "valuelist-scheduler",
  beforeDestroy() {
    clearInterval(this.send);
  },
  created() {
    this.sendData();
  },
  computed: {
    ...mapState(['authenticated']),
    ...mapGetters(['isOffline']),
  },
  methods: {
    sendData() {
      setTimeout(async() => this.sendItems(), 500);
      this.send = setInterval(async() => this.exchangeData(), 10000);
    },
    async sendItems() {
      if(this.authenticated && !this.isOffline) {
        await this.sendUnsentItems();
      }
    },
    async exchangeData() {
      if(this.authenticated && !this.isOffline) {
        await this.sendUnsentItems();
        await this.synchronizeActivities();
      }
    },
    ...mapActions({
      sendUnsentItems: 'valuelist/sendUnsentItems',
      synchronizeActivities: 'valuelist/synchronizeActivities',
    })
  }
}
</script>

<style scoped>

</style>