<template>
    <v-dialog v-model="dialog" max-width="500px" @keydown.esc="close" >
        <v-form v-model="valid" ref="form" @submit.prevent="save">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                    v-model="user.username"
                                    :label="$t('userManagement.editDialog.username')"
                                    :disabled="!newUser"
                                    :rules="[rules.required, rules.usernameLength]"
                                />
                            </v-col>

                            <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                    v-model="user.lastName"
                                    :label="$t('userManagement.editDialog.lastname')"
                                    :rules="[rules.required]"
                                />
                            </v-col>

                            <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                    v-model="user.firstName"
                                    :label="$t('userManagement.editDialog.firstname')"
                                    :rules="[rules.required]"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="6" md="4">
                                <v-checkbox v-model="user.enabled" class="shrink mr-0 mt-0" :label="$t('userManagement.editDialog.enabled')" />
                            </v-col>
                        </v-row>

                        <v-list shaped>
                            <v-subheader>{{ $t('userManagement.editDialog.roles') }}</v-subheader>
                            <v-list-item-group v-model="user.roles" multiple>
                                <template v-for="(role, idx) in roles">
                                    <v-divider v-if="!role" :key="`divider-${idx}`"></v-divider>

                                    <v-list-item
                                        v-else
                                        :key="`item-${idx}`"
                                        :value="role"
                                        active-class="deep-purple--text text--accent-4"
                                    >
                                        <template v-slot:default="{ active }">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ $t(`userManagement.role.${role}`) }}</v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-checkbox :input-value="active"  color="deep-purple accent-4"></v-checkbox>
                                            </v-list-item-action>
                                        </template>
                                    </v-list-item>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="blue darken-1" text @click="close">{{ $t('app.btn.cancel') }}</v-btn>
                    <v-btn color="blue darken-1" text type="submit" :disabled="invalid">{{ $t('app.btn.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>

        <v-dialog v-model="passwordDialog" max-width="500px" @keydown.esc="closePasswordDialog" persistent>
            <v-card>
                <v-card-title>
                    <span class="headline">{{ $t('userManagement.editDialog.passwordDialog.title') }}</span>
                </v-card-title>
                <v-card-text>
                    <p>
                        {{ $t('userManagement.editDialog.passwordDialog.text') }}
                        <b>{{ newPassword }} </b>
                    </p>
                    <p>{{ $t('userManagement.editDialog.passwordDialog.warning') }}</p>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="closePasswordDialog">{{ $t('app.btn.close') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>

import {mapActions, mapMutations, mapState} from "vuex";

    export default {
        name: "editDialog",

        mounted() {
            this.refresh();
        },

        data() {
            return {
                dialog: false,
                passwordDialog: false,
                newUser: null,
                newPassword: null,

                user: {
                    username: null,
                    firstName: null,
                    lastName: null,
                    password: null,
                    enabled: true,
                    roles: [],
                },

                defaultUser: {
                    username: null,
                    firstName: null,
                    lastName: null,
                    enabled: true,
                    roles: [],
                },

                valid: false,
                rules: {
                    required: value => !!value || this.$t('dialog.rules.required'),
                    usernameLength: value => (!!value && value.length > 2) || this.$t('dialog.rules.usernameLength')
                }
            }
        },

        computed: {
            formTitle() {
                return this.newUser ? this.$t('userManagement.editDialog.new') : this.$t('userManagement.editDialog.edit');
            },

            invalid() {
                return !this.user || !this.valid;
            },

            ...mapState('user-management', ['roles']),
        },

        methods: {
            refresh() {
                this.getRoles().then(success => {
                    if (!success) {
                        this.onNotification({message: this.$t('userManagement.editDialog.error.get.roles'), color: 'error'});
                    }
                });
            },

            open(user) {
                this.newUser = (user == null);
                this.newPassword = null;
                this.passwordDialog = false;

                if (this.newUser) {
                    Object.assign(this.user, this.defaultUser);

                } else {
                    Object.assign(this.user, user);
                }

                this.dialog = true;
            },

            save() {
                this.$refs.form.validate();

                if (this.invalid) return;

                let username = this.user.username;

                // create new user and show password
                if (this.newUser) {
                    this.createUser(this.user).then(password => {
                        if (password) {
                            this.newPassword = password;
                            this.passwordDialog = true;

                        } else {
                            this.onNotification({message: this.$t('userManagement.editDialog.error.create.user', [username]), color: 'error'});
                        }
                    });

                // update password
                } else {

                    this.updateUser(this.user).then(success => {
                        if (success) {
                            this.onNotification({message: this.$t('userManagement.editDialog.success.update.user', [username]), color: 'success'});

                        } else {
                            this.onNotification({message: this.$t('userManagement.editDialog.error.update.user', [username]), color: 'error'});
                        }
                    });
                }

                this.close();
            },

            close() {
                this.dialog = false;

                this.$nextTick(() => {
                    this.newPassword = null;
                    Object.assign(this.user, this.defaultUser);
                });
            },

            closePasswordDialog() {
                this.newPassword = '';
                this.passwordDialog = false;
            },

            ...mapMutations({
                onNotification: 'snackbar/onNotification'
            }),

            ...mapActions({
                getRoles: 'user-management/getRoles',
                createUser: 'user-management/createUser',
                updateUser: 'user-management/updateUser',
            })
        },

    }

</script>

<style scoped>

</style>