import { set } from '@/utils/vuex'

export default {
    setValueLists: set('valueLists'),
    addValueList(state, valueList) {
        state.valueLists.push(valueList);
    },
    replaceValueList(state, valueList){
        const index = state.valueLists.findIndex(a => a.uuid === valueList.uuid);
        state.valueLists.splice(index, 1, valueList);
    },
    replaceOrAddValueList(state, valueList){
        const index = state.valueLists.findIndex(a => a.uuid === valueList.uuid);
        if(index !== -1) {
            state.valueLists.splice(index, 1, valueList);
        } else {
            state.valueLists.push(valueList);
        }
    },
    removeValueList(state, valueList){
        const index = state.valueLists.findIndex(a => a.uuid === valueList.uuid);
        state.valueLists.splice(index, 1);
    },
    setValueList: set('valueList'),
    setValues: set('values'),
    addValue(state, value) {
        state.values.push(value);
    },
    replaceValue(state, value){
        const index = state.values.findIndex(a => a.uuid === value.uuid);
        state.values.splice(index, 1, value);
    },
    removeValue(state, valueUuid){
        const index = state.values.findIndex(a => a.uuid === valueUuid);
        state.values.splice(index, 1);
    },
    onValueUpdate(state) {
      state.updateView +=1;
    },

    setLocalActivitiesExist: set('localActivitiesExist'),
    setHistory: set('history'),
    setFetchState: set('fetchState'),
}