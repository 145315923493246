import Vue from 'vue';

// Components
import './components';

// Plugins
import './plugins';

// Sync router with store
import {sync} from 'vuex-router-sync';

// Application imports
import App from './App';
import i18n from './i18n';
import router from '@/router';
import store from '@/store';

import titleMixin from './mixins/titleMixin';

import axios from 'axios';
import VueAxios from 'vue-axios';
import VueMoment from 'vue-moment';

import './registerServiceWorker';

import vuetify from './plugins/vuetify';

Vue.config.productionTip = false

Vue.mixin(titleMixin);
Vue.use(VueAxios, axios);
Vue.use(VueMoment);

// Sync store with router
sync(store, router);

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: h => h(App),
  beforeDestroy() {
    store.dispatch('closeApp')
  },
  created() {
    if (store.state.authenticated) {
      store.dispatch('initApp');
    }
  },
}).$mount('#app');