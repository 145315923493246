<template>
  <div v-if="isOffline" style="position: relative">
    <span style="right: 0;position: absolute"><v-icon>mdi-wifi-off</v-icon></span>
  </div>
</template>

<script>
  import {mapState, mapActions, mapGetters} from "vuex";
  import api from "@/service/api";
  import apiEndpoints from "@/constants/api/apiEndpoints";
  import userService from '@/service/userService';

export default {
  name: "core-scheduler",
  computed: {
    ...mapState(['authenticated']),
    ...mapGetters(['isOffline']),
  },
  data() {
    return {
      send: null,
      getVersion: null,
    }
  },
  methods: {
    ...mapActions(['setOffline']),
    backendVersion() {
      this.checkOfflineStatus = setInterval(async() => this.setOfflineState(), 1000*10);
      this.getVersion = setInterval(async() => this.getBackendVersion(), 1000*60*60);
    },
    async getBackendVersion() {
      if(this.authenticated && !this.isOffline) {
        const response = await api().get(`${apiEndpoints.version}/current`).catch(err => console.error(err));
        if(response && response.status === 200) {
          localStorage.setItem('backVersion', response.data.gitSha)
          window.dispatchEvent(new CustomEvent('backVersion-changed', {
            detail: {
              backVersion: localStorage.getItem('backVersion')
            }
          }));
        }
      }
    },
    async setOfflineState() {
      const isOffline = await userService.checkOnlineStatus();
      this.setOffline(isOffline);
    },
  },
  beforeDestroy() {
    clearInterval(this.getVersion);
    clearInterval(this.checkOfflineStatus);
  },
  async created() {
    this.setOfflineState();
    this.getBackendVersion();
    this.backendVersion();
  }
}
</script>

<style scoped>

</style>
