import valueListService from "@/service/valueListService";
import Vue from "vue";
import i18n from "@/i18n";
import fileService from "@/service/fileService";

function compareActivities(a, b) {
  if (a.counter < b.counter) return -1;
  if (a.counter > b.counter) return 1;
  if (a.localCounter < b.localCounter) return -1;
  if (a.localCounter > b.localCounter) return 1;
  return 0;
}

const actions = {
  //valueList methods
  async getValueLists({ commit }, { fetchMode, isOffline }) {
    let valueLists = [];
    if (!isOffline) {
      const response = await valueListService.getValueListsFromApi(fetchMode);
      commit("setFetchState", fetchMode);
      if (response && response.status === 200) {
        valueLists = response.data;
        if (fetchMode === "LATEST") {
          await valueListService.saveValueListsToDb(valueLists, 1);
          const dbLists = await valueListService.getValueListsFromDb();
          const oldUuids = dbLists
            .filter((v) => v.sent === 1)
            .map((v) => v.uuid)
            .filter((uuid) => !valueLists.map((v) => v.uuid).includes(uuid));
          await valueListService.deleteOldListsAndValuesAndActivities(oldUuids);
        }
      } else {
        commit(
          "snackbar/onNotification",
          {
            message: i18n.t("app.error.noNetwork"),
            color: "error",
          },
          { root: true }
        );
        valueLists = await valueListService.getValueListsFromDb();
      }
    } else {
      valueLists = await valueListService.getValueListsFromDb();
    }
    for (const valueList of valueLists) {
      if (await valueListService.localActivitiesExist(valueList.uuid)) {
        valueList.state = "EDITED";
      }
    }
    commit("setValueLists", valueLists);
  },
  async getValueListById({ commit }, id) {
    const valueList = await valueListService.getValueListById(id);
    commit("setValueList", valueList);
  },
  async createValueList({ commit, state }, valList) {
    const valueList = JSON.parse(JSON.stringify(valList)); //need deep clone, not reference now
    if (state.valueLists.map((v) => v.name).includes(valueList.name))
      return false;
    const response = await valueListService.saveValueListToApi(
      valueList.uuid,
      valueList.name
    );
    if (response && response.status === 201) {
      const valueListApi = response.data;
      valueListApi.sent = 1;
      await valueListService.saveValueListToDb(valueListApi);
      await commit("addValueList", valueListApi);
      return valueListApi.uuid;
    } else {
      valueList.timestamp = Vue.moment().utc().toISOString();
      valueList.sent = 0;
      valueList.state = "LATEST";
      await valueListService.saveValueListToDb(valueList);
      await commit("addValueList", valueList);
      return valList.uuid;
    }
  },
  async updateValueListName(
    { commit, dispatch },
    { name, uuid, timestamp, listId }
  ) {
    let localCounter = await dispatch("getLocalActivityCounter", listId);
    const data = JSON.parse(JSON.stringify(name));
    const activity = {
      uuid: uuid,
      activityType: "UPDATE_VALUELIST_NAME",
      data: data,
      timestamp: timestamp,
      localCounter: ++localCounter,
    };
    await valueListService.saveActivityList([activity], listId, 0);
    await commit("setLocalActivitiesExist", true);
  },
  async deleteValueList({ commit }, valList) {
    const valueList = JSON.parse(JSON.stringify(valList)); //need deep clone, not reference now
    const response = await valueListService.deleteValueListAtApi(
      valueList.uuid
    );
    if (response && response.status === 204) {
      await valueListService.deleteOldListsAndValuesAndActivities([
        valList.uuid,
      ]);
      await commit("removeValueList", valueList);
      return true;
    } else {
      return false;
    }
  },
  async publishValueList({ commit }, listId) {
    const response = await valueListService.publishValueList(listId);
    if (response && response.status === 201) {
      await valueListService.deleteValueList(listId);
      const newList = response.data;
      newList.sent = 1;
      await valueListService.saveValueListToDb(newList);
      commit(
        "snackbar/onNotification",
        {
          message: i18n.t("valuelist.publish.success"),
          color: "success",
        },
        { root: true }
      );
      return true;
    } else {
      console.info(response);
      commit(
        "snackbar/onNotification",
        {
          message: i18n.t("valuelist.publish.error"),
          color: "error",
        },
        { root: true }
      );
      return false;
    }
  },

  //value methods
  async getValuesByListId({ commit, dispatch, state }, { listId, isOffline }) {
    let values = [];
    if (!isOffline) {
      const response = await valueListService.getValuesByListIdFromApi(listId);
      if (response && response.status === 200) {
        values = response.data;
        for (const value of values) {
          value.valuelistUuid = listId;
          await valueListService.saveValueToDb(value);
        }
      } else {
        commit(
          "snackbar/onNotification",
          {
            message: i18n.t("app.error.noNetwork"),
            color: "error",
          },
          { root: true }
        );
        values = await valueListService.getValuesByListId(listId);
      }
      await dispatch("fetchMissingListActivities", listId);
    } else {
      values = await valueListService.getValuesByListId(listId);
    }

    const activityList = await valueListService.getListActivitiesFromDb(listId);
    if (activityList.map((v) => v.sent).includes(0))
      commit("setLocalActivitiesExist", true);
    else commit("setLocalActivitiesExist", false);

    const sortedList = [...activityList].sort(compareActivities);
    for (let activity of sortedList) {
      if (activity.activityType === "UPDATE_VALUELIST_NAME") {
        state.valueList.name = activity.data;
      } else {
        valueListService.executeActivity(activity, values);
      }
    }
    await commit("setValues", values);
  },
  async fetchMissingListActivities(_, listid) {
    const activityList = await valueListService.getListActivitiesFromDb(listid);
    const currentCounter = Math.max(
      ...activityList
        .map((v) => v.counter)
        .filter((v) => v !== Number.MAX_VALUE),
      0
    );
    const response = await valueListService.getUnknownActivitiesByListIdFromApi(
      listid,
      currentCounter
    );
    if (response && response.status === 200) {
      const activities = response.data.activities;
      if (activities && activities.length) {
        await valueListService.saveActivityListToDb(activities);
        return activities;
      }
      return [];
    } else {
      console.info(response);
    }
    return [];
  },
  async deleteValue({ commit, dispatch }, { val, uuid, timestamp }) {
    let localCounter = await dispatch(
      "getLocalActivityCounter",
      val.valuelistUuid
    );
    const data = JSON.parse(JSON.stringify(val.uuid));
    const activity = {
      uuid: uuid,
      activityType: "DELETE_VALUE",
      data: data,
      timestamp: timestamp,
      localCounter: ++localCounter,
    };
    await valueListService.saveActivityList([activity], val.valuelistUuid, 0);
    await commit("removeValue", data);
    await commit("setLocalActivitiesExist", true);
  },

  //activities
  async getLocalActivityCounter(_, listId) {
    const unsentActivities = await valueListService.getUnsentActivitiesByListId(
      listId
    );
    const unsavedActivities =
      await valueListService.getUnsavedActivitiesByListId(listId);
    const counterList = unsentActivities
      .map((v) => v.localCounter)
      .concat(unsavedActivities.map((v) => v.localCounter));
    if (counterList.length) {
      return Math.max(...counterList);
    } else {
      return 0;
    }
  },
  async patchActivities({ commit }, { activityList, listId }) {
    await valueListService.saveActivityList(activityList, listId, 0);
    await commit("setLocalActivitiesExist", true);
  },
  async resetActivities({ commit }, listId) {
    await valueListService.removeAllLocalActivities(listId);
    await commit("setLocalActivitiesExist", false);
  },
  async saveAtBackend({ commit }, listId) {
    const activityList = await valueListService.getUnsavedActivitiesByListId(
      listId
    );
    const sortedList = [...activityList].sort(compareActivities);
    if (sortedList.length) {
      const success = await valueListService.sendUnsavedActivities(
        sortedList,
        listId
      );
      if (success) {
        const valuelist = await valueListService.getValueListById(listId);
        valuelist.state = "NEXT";
        await valueListService.saveValueListToDb(valuelist);
        await commit(
          "snackbar/onNotification",
          {
            message: i18n.t("valuelist.save.success"),
            color: "success",
          },
          { root: true }
        );
        return true;
      } else {
        const valuelist = await valueListService.getValueListById(listId);
        valuelist.state = "NEXT2";
        await valueListService.saveValueListToDb(valuelist);
        await commit(
          "snackbar/onNotification",
          {
            message: i18n.t("valuelist.save.error"),
            color: "info",
          },
          { root: true }
        );
        return true;
      }
    } else {
      await commit(
        "snackbar/onNotification",
        {
          message: i18n.t("valuelist.nochange"),
          color: "error",
        },
        { root: true }
      );
      return false;
    }
  },

  //synchronisation
  async sendUnsentItems({ commit, dispatch, state }) {
    //send new created valuelists
    const unsentLists = await valueListService.getUnsentValueLists();
    for (const list of unsentLists) {
      const response = await valueListService.saveValueListToApi(
        list.uuid,
        list.name
      );
      if (response && response.status === 201) {
        const valueListApi = response.data;
        valueListApi.sent = 1;
        await valueListService.saveValueListToDb(valueListApi);
        await commit("replaceOrAddValueList", valueListApi);
      } else if (response && response.status !== 404) {
        //delete if cant be saved
        await valueListService.deleteOldListsAndValuesAndActivities([
          list.uuid,
        ]);
      }
    }
    //send all activities grouped by valuelists
    const unsentActivities = await valueListService.getUnsentActivities();
    if (unsentActivities.length) {
      const listPerValuelist = {};
      for (const activity of unsentActivities) {
        if (activity.valuelistUuid in listPerValuelist) {
          listPerValuelist[activity.valuelistUuid].push(activity);
        } else {
          listPerValuelist[activity.valuelistUuid] = [activity];
        }
      }
      for (const [key, value] of Object.entries(listPerValuelist)) {
        const sortedList = [...value].sort(compareActivities);
        await valueListService.sendUnsentActivities(sortedList, key);
      }
      dispatch("getValueLists", {
        fetchMode: state.fetchState,
        isOffline: false,
      });
    }
  },
  async synchronizeActivities({ commit, state, dispatch }) {
    if (state.valueList) {
      //we are currently in a list-edit view
      const listId = state.valueList.uuid;
      let activities = await dispatch(
        "fetchMissingListActivities",
        state.valueList.uuid
      );
      if (activities.length) {
        let values = state.values;
        //dismiss local not saved changes
        const unsavedAct = await valueListService.getUnsavedActivitiesByListId(
          listId
        );
        if (unsavedAct.length) {
          await valueListService.deleteActivityList(unsavedAct);
          commit("setLocalActivitiesExist", false);
          commit(
            "snackbar/onNotification",
            {
              message: i18n.t("valuelist.sync.dismiss"),
              color: "info",
            },
            { root: true }
          );
          //clean values state
          values = await valueListService.getValuesByListId(listId);
          activities = await valueListService.getListActivitiesFromDb(listId);
        }
        state.valueList.state = "NEXT";
        const sortedList = [...activities].sort(compareActivities);
        for (let activity of sortedList) {
          if (activity.activityType === "UPDATE_VALUELIST_NAME") {
            state.valueList.name = activity.data;
          } else {
            valueListService.executeActivity(activity, values);
          }
        }
        await commit("setValues", values);
        await commit("onValueUpdate");
      }
    }
  },

  //upload
  async uploadAllValueLists(_, file) {
    const base64 = await fileService.getBase64StrFromFile(file);
    console.log(base64);
    const response = await valueListService.uploadAllLists(base64);
    if (response && response.status === 201) {
      const valuelistArray = response.data;
      for (const list of valuelistArray) {
        await valueListService.deleteActivitiesByListId(list.uuid);
      }
      return true;
    }
    return false;
  },
  async uploadValueList(_, { listId, file }) {
    const base64 = await fileService.getBase64StrFromFile(file);
    console.log(base64);
    const response = await valueListService.uploadList(listId, base64);
    if (response && response.status === 201) {
      const valuelist = response.data;
      await valueListService.deleteActivitiesByListId(valuelist.uuid);
      return valuelist;
    }
    return false;
  },

  //download
  async downloadLists(_, uuid) {
    const response = uuid
      ? await valueListService.downloadList(uuid)
      : await valueListService.downloadAllLists();
    if (response && response.status === 200) {
      const fileName = response.headers["content-disposition"]
        .split("filename=")[1]
        .replaceAll('"', "");
      return { data: response.data, filename: fileName };
    }
    return false;
  },

  //history
  async getValueListHistory({ commit }, listId) {
    const response = await valueListService.getValueListHistory(listId);
    if (response && response.status === 200) {
      const valueList = response.data;
      await commit("setHistory", valueList);
    }
  },
  async getHistoricValuesByListId({ commit }, listId) {
    const response = await valueListService.getHistoricValuesByListIdFromApi(
      listId
    );
    if (response && response.status === 200) {
      await commit("setValues", response.data);
    } else {
      commit(
        "snackbar/onNotification",
        {
          message: i18n.t("app.error.noNetwork"),
          color: "error",
        },
        { root: true }
      );
    }
  },
};

export default actions;
