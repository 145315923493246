<template>
    <v-dialog v-model="dialog" max-width="700px" @keydown.esc="close" >
        <v-form v-model="valid" ref="form" @submit.prevent="save">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ $t('userManagement.changePasswordDialog.title', [user?user.username:'']) }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="36" sm="18" md="12">
                                <v-text-field
                                    ref="password"
                                    tabindex="1"
                                    autocomplete="new-password"
                                    autofocus
                                    v-model="password"
                                    :label="$t('userManagement.changePasswordDialog.password')"
                                    :append-icon="visible ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="() => (visible = !visible)"
                                    :type="visible ? 'text' : 'password'"
                                    :rules="[rules.required, rules.password]"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="36" sm="18" md="12">
                                <v-text-field
                                    ref="repeatedPassword"
                                    autocomplete="new-password"
                                    tabindex="2"
                                    v-model="repeatedPassword"
                                    :label="$t('userManagement.changePasswordDialog.password.repeat')"
                                    :append-icon="visible ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="() => (visible = !visible)"
                                    :type="visible ? 'text' : 'password'"
                                    :rules="[rules.required, rules.equals]"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="blue darken-1" text tabindex="3" @click="close">{{ $t('app.btn.cancel') }}</v-btn>
                    <v-btn color="blue darken-1" text tabindex="4" type="submit" :disabled="invalid">{{ $t('app.btn.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

    import {mapActions, mapMutations} from "vuex";

    export default {
        name: "changePasswordDialog",

        data() {
            return {
                dialog: false,
                user: null,
                password: null,
                repeatedPassword: null,
                visible: false,
                valid: false,
                rules: {
                    required: value => !!value || this.$t('dialog.rules.password.required'),
                    password: value => {

                        return (
                            PASSWORD_PATTERN.test(value) || this.$t('dialog.rules.password.pattern')
                        );
                    },
                    equals: () => (this.password === this.repeatedPassword) || this.$t('dialog.rules.password.equals')
                }
            }
        },

        computed: {
            invalid() {
                return !this.user || !this.valid;
            }
        },

        methods: {
            open(user) {
                this.user = user;
                this.visible = false;
                this.password = '';
                this.repeatedPassword = null;
                this.dialog = true;

                if (this.$refs.form) this.$refs.form.reset();
            },

            save() {
                this.$refs.form.validate();
                if (this.invalid) return;

                let username = this.user.username;

                this.changePassword({user: this.user, password: this.password}).then(success => {
                    if (success) {
                        this.onNotification({message: this.$t('userManagement.changePasswordDialog.success', [username]), color: 'success'});
                    } else {
                        this.onNotification({message: this.$t('userManagement.changePasswordDialog.error', [username]), color: 'error'});
                    }
                });
                this.close();
            },

            close() {
                this.user = null;
                this.visible = false;
                this.password = false;
                this.dialog = false;
            },

            ...mapMutations({
                onNotification: 'snackbar/onNotification'
            }),

            ...mapActions({
                changePassword: 'user-management/changePassword'
            })
        },

        watch: {
            password() {
                this.$nextTick(() => {
                    this.$refs.repeatedPassword.validate();
                });
            }
        }
    }

</script>

<style scoped>

</style>