import api from "./api";
import idb from "../store/idb";
import apiEndpoints from "@/constants/api/apiEndpoints";

export default {
  //valuelist api
  async getValueListsFromApi(fetchMode) {
    return await api()
      .get(`${apiEndpoints.valuelist}/${fetchMode}`)
      .catch((err) => err);
  },
  async saveValueListToApi(uuid, listName) {
    return await api()
      .post(apiEndpoints.valuelist, { uuid: uuid, name: listName })
      .catch((err) => err);
  },
  async deleteValueListAtApi(id) {
    return await api()
      .delete(`${apiEndpoints.valuelist}/${id}`)
      .catch((err) => err);
  },
  async publishValueList(id) {
    return await api()
      .post(`${apiEndpoints.valuelist}/${id}/publish`)
      .catch((err) => err);
  },
  //valuelist local
  async getValueListsFromDb() {
    return await idb.getValueLists();
  },
  async getUnsentValueLists() {
    return await idb.getUnsentValueLists();
  },
  async saveValueListToDb(valueList) {
    return await idb.saveValueList(valueList);
  },
  async saveValueListsToDb(valueLists, sent) {
    for (const valueList of valueLists) {
      valueList.sent = sent;
      await idb.saveValueList(valueList);
    }
  },
  async updateValueList(valueList) {
    return await idb.saveValueList(valueList);
  },
  async getValueListById(id) {
    return await idb.getValueListById(id);
  },
  async deleteValueList(id) {
    return await idb.deleteValueList(id);
  },

  //value api
  async getValuesByListIdFromApi(listId) {
    return await api()
      .get(`${apiEndpoints.valuelist}/${listId}/values`)
      .catch((err) => err);
  },
  //value local
  async saveValueToDb(value) {
    await idb.saveValueListValue(value);
  },
  async updateValue(id, obj) {
    const value = await idb.getValueListValueById(id);
    const key = Object.keys(obj)[0];
    value[key] = obj[key];
    await idb.saveValueListValue(value);
    return value;
  },
  async getValuesByListId(id) {
    return await idb.getValueListValuesByListId(id);
  },
  async deleteValue(id) {
    return await idb.deleteValueListValue(id);
  },

  //activities api
  async getUnknownActivitiesByListIdFromApi(listId, listCounter) {
    return await api()
      .get(`${apiEndpoints.activity}/valuelist/${listId}/${listCounter}`)
      .catch((err) => err);
  },
  async sendActivityList(activityList, listId) {
    return await api()
      .post(`${apiEndpoints.activity}/valuelist/${listId}`, activityList)
      .catch((err) => err);
  },
  async sendUnsavedActivities(activityList, listId) {
    const response = await this.sendActivityList(
      this.getActivityListDTO(activityList),
      listId
    );
    if (response !== undefined && response.status === 201) {
      await this.saveActivityListToDb(response.data);
      return true;
    } else {
      await this.saveActivityList(activityList, listId, 1);
      return false;
    }
  },
  async sendUnsentActivities(activityList, listId) {
    const response = await this.sendActivityList(
      this.getActivityListDTO(activityList),
      listId
    );
    if (response !== undefined && response.status === 201) {
      await this.saveActivityListToDb(response.data);
    }
    //TODO may remove if still cant be sent?
  },
  getActivityListDTO(activityList) {
    const activityListDTO = [];
    for (const activity of activityList) {
      activityListDTO.push({
        uuid: activity.uuid,
        activityType: activity.activityType,
        data: activity.data,
        timestamp: activity.timestamp,
      });
    }
    return activityListDTO;
  },
  //activities local
  async saveActivityList(activityList, listId, saveAndSent) {
    for (const a of activityList) {
      await this.saveActivityToDb(
        a.uuid,
        a.activityType,
        listId,
        a.data,
        Number.MAX_VALUE,
        a.timestamp,
        saveAndSent,
        a.localCounter
      );
    }
  },
  async saveActivityListToDb(newActivities) {
    console.log("save Activities to DB: ");
    console.log(newActivities);
    if (newActivities.length) {
      for (const a of newActivities) {
        await this.saveActivityToDb(
          a.uuid,
          a.activityType,
          a.valuelistUuid,
          a.data,
          a.counter,
          a.timestamp,
          2,
          0
        );
      }
    }
  },
  async saveActivityToDb(
    uuid,
    activity,
    valuelistUuid,
    data,
    listCounter,
    timestamp,
    savedAndSent,
    localCounter
  ) {
    const listActivity = {
      uuid: uuid,
      activityType: activity,
      valuelistUuid: valuelistUuid,
      data: data,
      counter: listCounter,
      localCounter: localCounter,
      timestamp: timestamp,
      sent: savedAndSent, //0:"not saved/sent"; 1:"saved, not sent"; 2:"saved and sent"
    };
    return await idb.saveActivity(listActivity);
  },
  async localActivitiesExist(id) {
    return !!(await idb.getUnsavedActivitiesByListId(id)).length;
  },
  async getUnsentActivities() {
    return await idb.getUnsentActivities();
  },
  async getUnsavedActivitiesByListId(listId) {
    return await idb.getUnsavedActivitiesByListId(listId);
  },
  async getUnsentActivitiesByListId(listId) {
    return await idb.getUnsentActivitiesByListId(listId);
  },
  async updateActivity(activity) {
    await idb.saveActivity(activity);
  },
  async getListActivitiesFromDb(listId) {
    return await idb.getListActivities(listId);
  },
  executeActivity(activity, values) {
    const stringTypes = ["DELETE_VALUE"];
    const data = stringTypes.includes(activity.activityType)
      ? activity.data
      : JSON.parse(activity.data);
    switch (activity.activityType) {
      case "ADD_VALUE": {
        const newValue = {
          uuid: data.uuid,
          valuelistUuid: activity.valuelistUuid,
          name: data.value,
          labels: [],
        };
        values.push(newValue);
        break;
      }
      case "UPDATE_VALUE_NAME": {
        const value = values.find((v) => v.uuid === data.uuid);
        value.name = data.value;
        break;
      }
      case "ADD_LABEL": {
        const value = values.find((v) => v.uuid === data.valueUuid);
        value.labels.push({
          uuid: data.labelUuid,
          language: data.language,
          name: data.name,
        });
        break;
      }
      case "DELETE_LABEL": {
        const value = values.find((v) => v.uuid === data.valueUuid);
        const index = value.labels.findIndex((a) => a.uuid === data.labelUuid);
        value.labels.splice(index, 1);
        break;
      }
      case "UPDATE_LABEL": {
        const value = values.find((v) => v.uuid === data.valueUuid);
        const index = value.labels.findIndex((a) => a.uuid === data.labelUuid);
        value.labels.splice(index, 1, {
          uuid: data.labelUuid,
          language: data.language,
          name: data.name,
        });
        break;
      }
      case "DELETE_VALUE": {
        const index = values.findIndex((a) => a.uuid === data);
        values.splice(index, 1);
        break;
      }
    }
  },
  async removeAllLocalActivities(id) {
    const localActivities = await idb.getUnsavedActivitiesByListId(id);
    for (const a of localActivities) {
      await idb.deleteActivity(a.uuid);
    }
  },

  //download
  async downloadAllLists() {
    return await api()
      .get(`${apiEndpoints.valuelist}/download`, { responseType: "blob" })
      .catch((err) => err);
  },
  async downloadList(id) {
    return await api()
      .get(`${apiEndpoints.valuelist}/${id}/download`, { responseType: "blob" })
      .catch((err) => err);
  },

  //upload
  async uploadAllLists(data) {
    return await api()
      .post(`${apiEndpoints.valuelist}/upload`, data, {
        timeout: 1000 * 60 * 5,
        headers: { "Content-Type": "text/plain" },
      })
      .catch((err) => err);
  },
  async uploadList(id, data) {
    return await api()
      .post(`${apiEndpoints.valuelist}/${id}/upload`, data, {
        headers: { "Content-Type": "text/plain" },
      })
      .catch((err) => err);
  },

  //cleanup
  async deleteOldListsAndValuesAndActivities(uuidList) {
    for (const id of uuidList) {
      await idb.deleteValueListValuesByListId(id);
      await idb.deleteActivitiesByListId(id);
      await idb.deleteValueList(id);
    }
  },
  async deleteActivitiesByListId(id) {
    await idb.deleteActivitiesByListId(id);
  },
  async deleteActivityList(activities) {
    for (const act of activities) {
      await idb.deleteActivity(act.uuid);
    }
  },

  //history
  async getValueListHistory(listId) {
    return await api()
      .get(`${apiEndpoints.valuelist}/${listId}/history`)
      .catch((err) => err);
  },
  async getHistoricValuesByListIdFromApi(listId) {
    return await api()
      .get(`${apiEndpoints.valuelist}/${listId}/history/values`)
      .catch((err) => err);
  },
};
