<template>
    <v-dialog v-model="dialog" max-width="500px" @keydown.esc="close">
        <v-card>
            <v-card-title>{{ $t('userManagement.deleteDialog.title') }}</v-card-title>
            <v-card-text>
                {{ $t('valuelist.deleteDialog.valuelist.text', [valuelist.name]) }}
            </v-card-text>
            <v-card-actions>
                <v-btn color="blue darken-1" text @click="close">{{ $t('app.btn.cancel') }}</v-btn>
                <v-btn color="blue darken-1" text @click="deleteList">{{ $t('app.btn.yes') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapActions, mapMutations} from "vuex";

    export default {
        name: "deleteDialog",

        data() {
            return {
                dialog: false,
                valuelist: {}
            }
        },

        methods: {
            open(valuelist) {
                this.valuelist = Object.assign({}, valuelist);
                this.dialog = true;
            },
            async deleteList() {
                let name = this.valuelist.name;
                const success = await this.deleteValueList(this.valuelist);
                  if (success) {
                      this.onNotification({message: this.$t('valuelist.deleteDialog.success.delete.valuelist', [name]), color: 'success'});

                  } else {
                      this.onNotification({message: this.$t('valuelist.deleteDialog.error.delete.valuelist', [name]), color: 'error'});
                  }
                this.$emit('input', success);
                this.close();
            },
            close() {
                this.dialog = false;
                this.$nextTick(() => {
                    Object.assign(this.valuelist, {});
                });
            },
            ...mapMutations({
                onNotification: 'snackbar/onNotification'
            }),
            ...mapActions({
                deleteValueList: 'valuelist/deleteValueList'
            })
        },

    }

</script>

<style scoped>

</style>