<template>
  <v-main>
    <div class="fill-height">
      <v-overlay :value="loading" opacity="0.2">
          <v-progress-circular class="justify-center align-center" indeterminate color="primary" :size="100" width="8" />
      </v-overlay>
      <template>
          <core-alert-bar />
          <router-view v-show="!loading" />
      </template>
    </div>
  </v-main>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'DefaultView.vue',
  metaInfo () {
    return {
      title: 'VERS Core Data Tool'
    }
  },
    computed: {
    ...mapState(['loading'])
    },
}
</script>
