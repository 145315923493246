import xsdService from "@/service/xsdService";
import i18n from "@/i18n";
import fileService from "@/service/fileService";

const actions = {
    async getXsdList({commit}) {
        const response = await xsdService.getXsdListFromApi();
        if(response && response.status === 200) {
            commit('setXsdList', response.data);
        } else {
            commit('snackbar/onNotification', {
                message: i18n.t('app.error.noNetwork'),
                color: 'error',
            }, {root: true});
        }
    },
    async getXsdContent(_, uuid) {
        const response = await xsdService.getXsdContent(uuid);
        if(response && response.status === 200) {
            return response.data;
        }
        return {}
    },
    async uploadXsd({commit}, {xsdFile, name, filename, comment}) {
        const base64 = await fileService.getBase64StrFromFile(xsdFile);

        const xsd = {name: name, filename: filename, data: base64, comment: comment};
        const response = await xsdService.uploadXsd(xsd);
        if(response && response.status === 201) {
            const newXsd = response.data;
            await commit('addOrReplaceXsd', newXsd);
            return newXsd;
        }
        return false;
    },
    async publishXsd({dispatch}, xsd) {
        const response = await xsdService.publishXsd(xsd.uuid);
        if(response && response.status === 201) {
            dispatch('getXsdList');
            return true;
        }
        return false;
    },
    async deleteXsd({commit}, xsdFile) {
        const xsd = JSON.parse(JSON.stringify(xsdFile)); //need deep clone, not reference now
        const response = await xsdService.deleteXsd(xsd.uuid);
        if(response && response.status === 204) {
            await commit('removeXsd', xsd);
            return true;
        }
        return false;
    },
    async getXsdErrors({commit}, uuid) {
      const response = await xsdService.getXsdErrors(uuid);
      if(response && response.status === 200) {
          return response.data;
      } else {
          commit('snackbar/onNotification', {
              message: i18n.t('app.error.noNetwork'),
              color: 'error',
          }, {root: true});
          return [];
      }
    },
    async downloadXsd(_, xsd) {
      const response = await xsdService.downloadXsd(xsd.uuid);
      if(response && response.status === 200) {
          const fileName = response.headers['content-disposition'].split("filename=")[1].replaceAll('"', '');
          return {data: response.data, filename: fileName};
      }
      return false;
    },

}

export default actions;