import ROLES from "@/constants/user/roles";

export default [
    {
        path: '/',
        view: 'StartPage',
        meta: {
            authorize: [ROLES.ROLE_ADMIN, ROLES.ROLE_STAFF, ROLES.ROLE_VALUELIST, ROLES.ROLE_XSD]
        },
    },
    {
        path: '/valuelistManagement',
        view: 'ValueListManagement',
        meta: {
            title: 'valuelistManagement',
            authorize: [ROLES.ROLE_ADMIN, ROLES.ROLE_STAFF, ROLES.ROLE_VALUELIST, ROLES.ROLE_XSD]
        }
    },
    {
        path: '/valuelistManagement/:listId/:historic?',
        view: 'ValueListEdit',
        meta: {
            title: 'valuelistedit',
            authorize: [ROLES.ROLE_ADMIN, ROLES.ROLE_STAFF, ROLES.ROLE_VALUELIST, ROLES.ROLE_XSD]
        }
    },
    {
        path: '/valuelistUpload/:listId?',
        view: 'ValueListUpload',
        meta: {
            title: 'valuelistupload',
            authorize: [ROLES.ROLE_ADMIN, ROLES.ROLE_STAFF, ROLES.ROLE_VALUELIST, ROLES.ROLE_XSD]
        }
    },
    {
        path: '/valuelistHistory/:listId',
        view: 'ValueListHistory',
        meta: {
            title: 'valuelisthistory',
            authorize: [ROLES.ROLE_ADMIN, ROLES.ROLE_STAFF, ROLES.ROLE_VALUELIST, ROLES.ROLE_XSD]
        }
    },
    {
        path: '/xsdManagement',
        view: 'XsdManagement',
        meta: {
            title: 'xsdManagement',
            authorize: [ROLES.ROLE_ADMIN, ROLES.ROLE_STAFF, ROLES.ROLE_VALUELIST, ROLES.ROLE_XSD]
        }
    },
    {
        path: '/xsdManagement/upload',
        view: 'XsdUpload',
        meta: {
            title: 'xsdupload',
            authorize: [ROLES.ROLE_ADMIN, ROLES.ROLE_STAFF, ROLES.ROLE_VALUELIST, ROLES.ROLE_XSD]
        }
    },
    {
        path: '/xsdManagement/:xsdId',
        view: 'XsdView',
        meta: {
            title: 'xsdview',
            authorize: [ROLES.ROLE_ADMIN, ROLES.ROLE_STAFF, ROLES.ROLE_VALUELIST, ROLES.ROLE_XSD]
        }
    },
    {
        path: '/xsdErrors/:xsdId',
        view: 'XsdErrors',
        meta: {
            title: 'xsderrors',
            authorize: [ROLES.ROLE_ADMIN, ROLES.ROLE_STAFF, ROLES.ROLE_VALUELIST, ROLES.ROLE_XSD]
        }
    },
    {
        path: '/login',
        view: 'Login',
        props: true,
    },
    {
        path: '/profile',
        view: 'Profile',
        meta: {
            title: 'profile',
            authorize: [ROLES.ROLE_ADMIN, ROLES.ROLE_STAFF, ROLES.ROLE_VALUELIST, ROLES.ROLE_XSD]
        }
    },
    {
        path: '/mailManagement',
        view: 'MailManagement',
        meta: {
            title: 'mailManagement',
            authorize: [ROLES.ROLE_ADMIN, ROLES.ROLE_STAFF, ROLES.ROLE_VALUELIST, ROLES.ROLE_XSD]
        }
    },
    {
        path: '/userManagement',
        view: 'UserManagement',
        comp: () =>
            import('@/views/UserManagement'),

        meta: {
            title: 'userManagement',
            authorize: [ROLES.ROLE_ADMIN]
        },
    }
]
