<template>
    <v-dialog v-model="dialog" max-width="500px" @keydown.esc="close">
        <v-card>
            <v-card-title>{{ $t('valuelist.reset') }}</v-card-title>
            <v-card-text>
                {{ $t('valuelist.resetDialog.text') }}
            </v-card-text>
            <v-card-actions>
                <v-btn color="blue darken-1" text @click="close">{{ $t('app.btn.cancel') }}</v-btn>
                <v-btn color="blue darken-1" text @click="deleteList">{{ $t('app.btn.yes') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapActions, mapMutations} from "vuex";

    export default {
        name: "deleteDialog",

        data() {
            return {
                dialog: false,
                uuid: null
            }
        },

        methods: {
            open(id) {
                this.uuid = id
                this.dialog = true;
            },
            async deleteList() {
                await this.resetActivities(this.uuid);
                this.onNotification({message: this.$t('valuelist.resetDialog.success'), color: 'success'});
                this.$emit('input', true);
                this.close();
            },
            close() {
                this.dialog = false;
                this.$nextTick(() => {
                    this.uuid = null;
                });
            },
            ...mapMutations({
                onNotification: 'snackbar/onNotification',
            }),
            ...mapActions({
                resetActivities: 'valuelist/resetActivities',
            })
        },

    }

</script>

<style scoped>

</style>