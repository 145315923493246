const DB_NAME = 'valuelistdb';
const DB_VERSION = 1;
let DB;

const OBJECT_STORES = [
    {
        table: 'valueLists',
        keyPath: {keyPath: 'uuid'},
        indexes: [
            {name: 'sent', indexOptions: {unique: false}},
        ]
    },
    {
        table: 'valueListValues',
        keyPath: {keyPath: 'uuid'},
        indexes: [
            {name: 'valuelistUuid', indexOptions: {unique: false}},
        ]
    },
    {
        table: 'activities',
        keyPath: {keyPath: 'uuid'},
        indexes: [
            {name: 'valuelistUuid', indexOptions: {unique: false}},
            {name: 'sent', indexOptions: {unique: false}},
            {name: 'counter', indexOptions: {unique: false}},
            {name: 'sent, valuelistUuid', keyPath: ['sent', 'valuelistUuid'], indexOptions: {unique: false}},
        ]
    },
];

export default {
    async getDb() {
        return new Promise((resolve, reject) => {
            if (DB) {
                return resolve(DB);
            }
            let request = window.indexedDB.open(DB_NAME, DB_VERSION);

            request.onerror = e => {
                console.log('Error opening db', e);
                alert('Es gab einen Fehler beim Laden der Datenbank, bitte prüfen Sie, dass sie nicht im privaten Modus arbeiten');
                reject('Error');
            };

            request.onsuccess = e => {
                DB = e.target.result;
                resolve(DB);
            };

            request.onupgradeneeded = e => {
                console.log('onupgradeneeded');
                let db = e.target.result;
                if(e.oldVersion < 1) {
                    OBJECT_STORES.forEach(conf => {
                        const keyPath = conf.keyPath || {keyPath: 'id', autoIncrement: true};
                        let objectStore = db.createObjectStore(conf.table, keyPath);
                        if (conf.indexes) {
                            conf.indexes.forEach(index => {
                                if (index.keyPath) objectStore.createIndex(index.name, index.keyPath, index.indexOptions)
                                else objectStore.createIndex(index.name, index.name, index.indexOptions)
                            })
                        }
                    })
                }
            };
        });
    },
    async getById(tableName, id) {
        let db = await this.getDb();
        return new Promise(resolve => {
            let trans = db.transaction([tableName], 'readonly');
            trans.oncomplete = () => {
                resolve(item);
            };

            let store = trans.objectStore(tableName);
            const request = store.get(id)
            let item = null;
            request.onsuccess = () => {
                item = request.result;
            }
        });
    },
    async getByIndex(tableName, index, query) {
        let db = await this.getDb();
        return new Promise(resolve => {
            let trans = db.transaction([tableName], 'readonly');
            trans.oncomplete = () => {
                resolve(results);
            };

            let store = trans.objectStore(tableName);
            let results = [];

            store.index(index).openCursor(query).onsuccess = e => {
                let cursor = e.target.result;
                if (cursor) {
                    results.push(cursor.value)
                    cursor.continue();
                }
            };
        });
    },
    async getValueListById(id) {
        return this.getById('valueLists',  id);
    },
    async getUnsentValueLists() {
        return this.getByIndex('valueLists', 'sent', 0);
    },
    async getValueListValueById(id) {
        return this.getById('valueListValues', id);
    },
    async getValueListValuesByListId(listId) {
        return this.getByIndex('valueListValues', 'valuelistUuid', listId);
    },
    async getUnsentActivities() {
        return this.getByIndex('activities', 'sent', 1);
    },
    async getUnsavedActivitiesByListId(id) {
        const sent = 0;
        const sentAndListID = [sent, id];
        return this.getByIndex('activities', 'sent, valuelistUuid', sentAndListID);
    },
    async getUnsentActivitiesByListId(id) {
        const sent = 1;
        const sentAndListID = [sent, id];
        return this.getByIndex('activities', 'sent, valuelistUuid', sentAndListID);
    },
    async getActivitiesWithPositiveCounter() {
        return this.getByIndex('activities', 'counter', IDBKeyRange.lowerBound(0));
    },
    async getListActivities(listId) {
        return this.getByIndex('activities', 'valuelistUuid', listId);
    },
    async getAll(tableName) {
        let db = await this.getDb();
        return new Promise(resolve => {
            let trans = db.transaction([tableName], 'readonly');
            trans.oncomplete = () => {
                resolve(items);
            };

            let store = trans.objectStore(tableName);
            let items = [];

            store.openCursor().onsuccess = e => {
                let cursor = e.target.result;
                if (cursor) {
                    items.push(cursor.value)
                    cursor.continue();
                }
            };
        });
    },
    async getValueLists() {
        return this.getAll('valueLists');
    },
    async saveAll(tableName, items) {
        let db = await this.getDb();
        return new Promise(resolve => {
            let trans = db.transaction([tableName], 'readwrite');
            trans.oncomplete = () => {
                resolve();
            };

            let store = trans.objectStore(tableName);
            items.forEach(item => {
                store.put(item);
            })
        });
    },
    async saveValueList(valueList) {
        return this.save('valueLists', valueList);
    },
    async saveValueListValue(value) {
        return this.save('valueListValues', value);
    },
    async saveActivity(activity) {
        return this.save('activities', activity);
    },

    async save(tableName, item) {
        let db = await this.getDb();
        return new Promise(resolve => {
            let trans = db.transaction([tableName], 'readwrite');

            let store = trans.objectStore(tableName);
            const request = store.put(item);
            request.onsuccess = e => {
                resolve(e.target.result);
            }
        });
    },
    async deleteValueList(id) {
        return this.delete('valueLists', id);
    },
    async deleteValueListValue(id) {
        return this.delete('valueListValues', id);
    },
    async deleteActivity(id) {
        return this.delete('activities', id)
    },
    async deleteValueListValuesByListId(id) {
        const values = await this.getValueListValuesByListId(id);
        for (const value of values) {
            await this.deleteValueListValue(value.uuid)
        }
    },
    async deleteActivitiesByListId(id) {
        const activities = await this.getListActivities(id);
        for (const activity of activities) {
            await this.deleteActivity(activity.uuid)
        }
    },
    async delete(tableName, id) {
        let db = await this.getDb();
        return new Promise(resolve => {
            let trans = db.transaction([tableName], 'readwrite');
            trans.oncomplete = () => {
                resolve(id);
            };

            let store = trans.objectStore(tableName);
            store.delete(id)
        });
    },
}
