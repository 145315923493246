
export default {

    //utility
    async getBase64StrFromFile(file) {
        const dataUrl = await this.convertImageToBase64Str(file)
        return dataUrl.replace('data:', '')
            .replace(/^.+,/, '');
    },
    async convertImageToBase64Str(imageBlob) {
        let reader = new FileReader();
        reader.readAsDataURL(imageBlob); // converts the blob to base64 and calls onload

        return new Promise(resolve => {
            reader.onload = function () {
                resolve(reader.result);
            };
        });
    },
}