import api from "./api";
import { jwtDecode } from "jwt-decode";
import apiEndpoints from "@/constants/api/apiEndpoints";

function getRandomString() {
  return Math.random().toString(36).substring(2, 15);
}

export default {
  async checkOnlineStatus() {
    try {
      // avoid CORS errors with a request to your own origin
      const url = new URL(window.location.origin);
      // random value to prevent cached responses
      url.searchParams.set("rand", getRandomString());
      const online = await fetch(url.toString());
      return online.status < 200 || online.status >= 300; // either true or false
    } catch (err) {
      return true; // definitely offline
    }
  },

  isAuthenticated() {
    return !!this.getToken();
  },

  getRolesFromToken() {
    let decodedToken = this.getDecodedToken();
    return (decodedToken != null && decodedToken["roles"]) || [];
  },

  getDecodedToken() {
    const token = this.getToken();
    if (token) {
      return jwtDecode(token);
    }
    return null;
  },

  getUser() {
    const user = window.localStorage.getItem("vers-mdm-user");
    return user ? JSON.parse(user) : {};
  },

  getToken() {
    return this.getUser().token;
  },

  async login(state, { userName, password }) {
    const userResponse = await api()
      .post(`${apiEndpoints.auth}/signin`, {
        userName,
        password,
      })
      .catch((err) => console.error(err));

    if (userResponse) {
      window.localStorage.setItem(
        "vers-mdm-user",
        JSON.stringify(userResponse.data)
      );
      state.logoutReason = undefined;
      state.authenticated = true;
      state.roles = userResponse.data.roles;
      return true;
    }
    return false;
  },

  logout(state, logoutReason) {
    state.authenticated = false;
    state.logoutReason = logoutReason;
    state.roles = [];
    window.localStorage.removeItem("vers-mdm-user");
  },
};
