import Vue from 'vue'
import theme from './theme'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
    theme,
    icons: {
        iconfont: 'mdi',
    }
});