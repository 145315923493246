<template>
    <v-dialog v-model="dialog" max-width="500px" @keydown.esc="close">
        <v-card>
            <v-card-title>{{ $t('userManagement.deleteDialog.title') }}</v-card-title>
            <v-card-text>
                {{ $t('xsd.deleteDialog.text', [xsd.name]) }}
            </v-card-text>
            <v-card-actions>
                <v-btn color="blue darken-1" text @click="close">{{ $t('app.btn.cancel') }}</v-btn>
                <v-btn color="blue darken-1" text @click="deleteList">{{ $t('app.btn.yes') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapActions, mapMutations} from "vuex";
export default {
    name: "deleteDialog",
    data() {
        return {
            dialog: false,
            xsd: {}
        }
    },
    methods: {
        open(xsd) {
            this.xsd = Object.assign({}, xsd);
            this.dialog = true;
        },
        deleteList() {
            let name = this.xsd.name;
            this.deleteXsd(this.xsd).then(success => {
                if (success) {
                    this.onNotification({message: this.$t('xsd.deleteDialog.success.delete.xsd', [name]), color: 'success'});

                } else {
                    this.onNotification({message: this.$t('xsd.deleteDialog.error.delete.xsd', [name]), color: 'error'});
                }
            });
            this.close();
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                Object.assign(this.xsd, {});
            });
        },
        ...mapMutations({
            onNotification: 'snackbar/onNotification'
        }),
        ...mapActions({
            deleteXsd: 'xsd/deleteXsd'
        })
    },
}

</script>

<style scoped>

</style>