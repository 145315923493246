// https://vuex.vuejs.org/en/mutations.html
import userService from "../service/userService";
import {set} from '@/utils/vuex';

export default {
    onLanguageChanged(state, payload) {
        window.localStorage.setItem('vers-mdm-lang', payload.language);
        payload.$root.$i18n.locale = payload.language;
        state.language = payload.language;
        document.querySelector('html').setAttribute('lang', payload.language);
    },
    setOffline(state, isOffline) {
        state.isOffline = isOffline
    },
    async login(state, loginData) {
        return await userService.login(state, loginData)
    },
    async logout(state) {
        await userService.logout(state)
        state.authenticated = false;
    },
    setUser: set('user'),
    setAuthenticated: set('authenticated'),
    setLoading: set('loading')
}
