import {set} from "@/utils/vuex";

export default {
    setXsdList: set('xsdList'),
    addXsd(state, xsd) {
        state.xsdList.push(xsd);
    },
    replaceXsd(state, xsd) {
        const index = state.xsdList.findIndex(a => a.uuid === xsd.uuid);
        state.xsdList.splice(index, 1, xsd);
    },
    removeXsd(state, xsd){
        const index = state.xsdList.findIndex(a => a.uuid === xsd.uuid);
        state.xsdList.splice(index, 1);
    },
    addOrReplaceXsd(state, xsd) {
        const index = state.xsdList.findIndex(a => a.uuid === xsd.uuid);
        if(index !== -1) {
            state.xsdList.splice(index, 1, xsd);
        } else {
            state.xsdList.push(xsd);
        }
    }
}