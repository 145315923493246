<template>
    <v-dialog v-model="dialog" max-width="500px" @keydown.esc="close">
        <v-card>
            <v-card-title>{{ $t('userManagement.deleteDialog.title') }}</v-card-title>
            <v-card-text>
                {{ $t('valuelist.deleteDialog.value.text', [value.key]) }}
            </v-card-text>
            <v-card-actions>
                <v-btn color="blue darken-1" text @click="close">{{ $t('app.btn.cancel') }}</v-btn>
                <v-btn color="blue darken-1" text @click="deleteVal">{{ $t('app.btn.yes') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapActions} from "vuex";
import Vue from "vue";
import {v4 as uuidv4} from "uuid";

    export default {
        name: "deleteDialog",
        data() {
            return {
                dialog: false,
                value: {}
            }
        },
        methods: {
            open(value) {
                this.value = Object.assign({}, value);
                this.dialog = true;
            },
            deleteVal() {
                this.deleteValue({val: this.value, uuid: uuidv4(), timestamp: Vue.moment().utc().toISOString()});
                this.$emit('input', true);
                this.close();
            },
            close() {
                this.dialog = false;

                this.$nextTick(() => {
                    Object.assign(this.value, {});
                });
            },
            ...mapActions({
                deleteValue: 'valuelist/deleteValue'
            })
        },

    }

</script>

<style scoped>

</style>