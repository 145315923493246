import api from "@/service/api";
import apiEndpoints from "@/constants/api/apiEndpoints";

export default {
  getRolesFromApi() {
    return api()
      .get(`${apiEndpoints.users}/roles`)
      .catch((err) => {
        console.error(err);
        return err;
      });
  },

  getUsersFromApi() {
    return api()
      .get(`${apiEndpoints.users}`)
      .catch((err) => {
        console.error(err);
        return err;
      });
  },

  createUser({ username, firstName, lastName, roles }) {
    return api()
      .post(apiEndpoints.users, { username, firstName, lastName, roles })
      .catch((err) => {
        console.error(err);
        return err;
      });
  },

  updateUser({ id, firstName, lastName, roles, enabled }) {
    return api()
      .put(`${apiEndpoints.users}/${id}`, {
        firstName,
        lastName,
        roles,
        enabled,
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  },

  changePassword(id, password) {
    return api()
      .put(`${apiEndpoints.users}/${id}/changePassword`, password, {
        headers: { "Content-Type": "text/plain" },
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  },

  deleteUser(id) {
    return api()
      .delete(`${apiEndpoints.users}/${id}`)
      .catch((err) => {
        console.error(err);
        return err;
      });
  },
};
