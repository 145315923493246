export default {
    dark: false,
    themes: {
        light: {
            primary: '#005087',
            secondary: '#4caf50',
            tertiary: '#495057',
            accent: '#fdc300',
            error: '#f55a4e',
            info: '#00bcd4',
            success: '#5cb860',
            warning: '#ffa21a'
        }
    }
}
