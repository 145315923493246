<template>
    <v-dialog v-model="dialog" max-width="600px" @keydown.esc="close" >
        <v-form v-model="valid" ref="form" @submit.prevent="save" :readonly="isDeleted">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="value.name"
                                    :label="$t('table.field.name')"
                                    :rules="[rules.required, rules.uniqueName]"
                                    autofocus
                                />
                            </v-col>
                            <v-col cols="12" sm="4" md="3">
                                <v-text-field
                                    v-bind:value="nameExists('de') ? getLangName('de') : ''"
                                    v-on:input="setValue('de', $event)"
                                    :label="$t('language.de')"
                                    @focus="$event.target.select()"
                                />
                            </v-col>
                            <v-col cols="12" sm="4" md="3" v-for="item of langCodes" v-bind:key="item">
                              <v-text-field
                                  v-bind:value="nameExists(item) ? getLangName(item) : getLangName('de')"
                                  v-bind:style="[nameExists(item) ? {} : {'opacity':'0.5'}]"
                                  v-on:input="setValue(item, $event)"
                                  :label="$t('language.'+item)"
                                  @focus="$event.target.select()"
                              />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="blue darken-1" text @click="close">{{ $t('app.btn.cancel') }}</v-btn>
                    <v-btn color="blue darken-1" text type="submit" :disabled="invalid || isDeleted">{{ $t('app.btn.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>

    </v-dialog>
</template>

<script>
import {v4 as uuidv4} from 'uuid';
import {mapActions, mapMutations, mapState} from "vuex";
import Vue from "vue";
import LANGUAGES from "@/constants/languages";

    export default {
        name: "editDialog",

        data() {
            return {
                langCodes: LANGUAGES.filter(item => item !== 'de'),
                dialog: false,
                newValue: null,
                isDeleted: true,
                value: {
                    uuid: null,
                    valuelistUuid : null,
                    name: null,
                    labels: []
                },
                defaultValue: {
                    uuid: null,
                    valuelistUuid : null,
                    name: null,
                    labels: []
                },
                oldValue: {},
                valid: false,
                rules: {
                    required: value => !!value || this.$t('dialog.rules.required'),
                    uniqueName: value => this.nameAlreadyExists(value) || this.$t('dialog.rules.unique'),
                }
            }
        },

        computed: {
            formTitle() {
                return this.isDeleted ? this.$t('valuelist.editDialog.view.value')
                    : this.newValue ? this.$t('valuelist.editDialog.new.value') : this.$t('valuelist.editDialog.edit.value');
            },
            invalid() {
                return !this.value || !this.valid;
            },
            ...mapState('valuelist', ['updateView', 'values']),
        },
        watch: {
          updateView() { //another user changed some values
            this.close();
          }
        },
        methods: {
            nameAlreadyExists(name) {
              if(!this.values) return false;
              const names = this.values.filter(v=>v.uuid !== this.value.uuid).map(v=>v.name);
              return !names.includes(name);
            },
            setValue(prop, event) {
              if(event === "") event = null;
              if(Object.keys(this.value.labels).length !== 0 && this.value.labels.find(v => v.language === prop)) {
                this.$set(this.value.labels.find(v => v.language === prop), 'name', event);
              } else {
                this.value.labels.push({uuid: uuidv4(), language: prop, name: event})
              }

            },
            getLangName(langCode) {
              if( Object.keys(this.value.labels).length === 0) return null;
              return this.value.labels.find(v => v.language === langCode)['name'];
            },
            nameExists(langCode) {
              if( Object.keys(this.value.labels).length === 0) return false;
              const obj = this.value.labels.find(v => v.language === langCode)
              return obj && obj['name']
            },
            open(listId, isDeleted, openedValue) {
                this.newValue = (openedValue == null);
                this.isDeleted = isDeleted;

                if (this.newValue) {
                    this.value = JSON.parse(JSON.stringify(this.defaultValue));
                    this.oldValue = JSON.parse(JSON.stringify(this.defaultValue));
                    this.value.valuelistUuid = listId;
                } else {
                    this.value = JSON.parse(JSON.stringify(openedValue));
                    this.oldValue = JSON.parse(JSON.stringify(openedValue));
                }
                this.dialog = true;
            },

            async save() {
                this.$refs.form.validate();
                if (this.invalid) return;

                if(this.newValue) {
                  this.value.uuid = uuidv4();
                }
                await this.updateValue();
                this.$emit('input', true);
                this.close();
            },
            async updateValue() {
              const activityList = [];
              let actCounter = await this.getLocalActivityCounter(this.value.valuelistUuid);
              const timestamp = Vue.moment().utc().toISOString();
              const data = {uuid: this.value.uuid, value: this.value.name};
              if(this.newValue) {
                activityList.push({uuid: uuidv4(), activityType: 'ADD_VALUE', data: JSON.stringify(data), timestamp: timestamp, localCounter: ++actCounter});
              } else if(this.value.name !== this.oldValue.name) {
                activityList.push({uuid: uuidv4(), activityType: 'UPDATE_VALUE_NAME', data: JSON.stringify(data), timestamp: timestamp, localCounter: ++actCounter});
              }
              for (let item of this.value.labels) {
                const oldItem = this.oldValue.labels.find(v => v.uuid === item.uuid);
                const data = {valueUuid: this.value.uuid, labelUuid: item.uuid, name: item.name, language: item.language};
                if(!oldItem || !oldItem.name) {
                  activityList.push({uuid: uuidv4(), activityType: 'ADD_LABEL', data: JSON.stringify(data), timestamp: timestamp, localCounter: ++actCounter});
                } else if(!item.name) {
                  const deleteData = {valueUuid: this.value.uuid, labelUuid: item.uuid};
                  activityList.push({uuid: uuidv4(), activityType: 'DELETE_LABEL', data: JSON.stringify(deleteData), timestamp: timestamp, localCounter: ++actCounter});
                } else if(item.name !== oldItem.name) {
                  activityList.push({uuid: uuidv4(), activityType: 'UPDATE_LABEL', data: JSON.stringify(data), timestamp: timestamp, localCounter: ++actCounter});
                }
              }
              if(activityList.length) {
                this.patchActivities({activityList: activityList, listId: this.value.valuelistUuid});
                if(this.newValue) {
                  this.addValue(this.value);
                } else {
                  this.replaceValue(this.value);
                }
              }
            },
            close() {
                this.dialog = false;

                this.$nextTick(() => {
                    this.value = JSON.parse(JSON.stringify(this.defaultValue));
                });
            },
            closePasswordDialog() {
                this.newPassword = '';
                this.passwordDialog = false;
            },
            ...mapMutations({
                onNotification: 'snackbar/onNotification',
                replaceValue: 'valuelist/replaceValue',
                addValue: 'valuelist/addValue',
            }),
            ...mapActions({
                getLocalActivityCounter: 'valuelist/getLocalActivityCounter',
                patchActivities: 'valuelist/patchActivities',
            })
        },

    }

</script>

<style scoped>

</style>