<template>
    <v-dialog v-model="dialog" max-width="500px" @keydown.esc="close">
        <v-card>
            <v-card-title>{{ $t('userManagement.deleteDialog.title') }}</v-card-title>
            <v-card-text>
                {{ $t('userManagement.deleteDialog.text', [user.username]) }}
            </v-card-text>
            <v-card-actions>
                <v-btn color="blue darken-1" text @click="close">{{ $t('app.btn.cancel') }}</v-btn>
                <v-btn color="blue darken-1" text @click="del1">{{ $t('app.btn.yes') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapActions, mapMutations} from "vuex";

    export default {
        name: "deleteDialog",

        data() {
            return {
                dialog: false,
                user: {}
            }
        },

        methods: {

            open(user) {
                this.user = Object.assign({}, user);

                this.dialog = true;
            },

            del1() {
                let username = this.user.username;

                this.deleteUser(this.user).then(success => {
                    if (success) {
                        this.onNotification({message: this.$t('userManagement.deleteDialog.success.delete.user', [username]), color: 'success'});

                    } else {
                        this.onNotification({message: this.$t('userManagement.deleteDialog.error.delete.user', [username]), color: 'error'});
                    }
                });

                this.close();
            },

            close() {
                this.dialog = false;

                this.$nextTick(() => {
                    Object.assign(this.user, this.defaultUser);
                });
            },

            ...mapMutations({
                onNotification: 'snackbar/onNotification'
            }),

            ...mapActions({
                deleteUser: 'user-management/deleteUser'
            })
        },

    }

</script>

<style scoped>

</style>