<template>
    <v-dialog v-model="dialog" max-width="500px" @keydown.esc="close">
        <v-card>
            <v-card-title>{{ $t('dialog.title.publish') }}</v-card-title>
            <v-card-text>
                {{ $t('valuelist.applyDialog.text', [valuelist.name]) }}
            </v-card-text>
            <v-card-actions>
                <v-btn color="blue darken-1" text @click="close">{{ $t('app.btn.cancel') }}</v-btn>
                <v-btn color="blue darken-1" text @click="applyList">{{ $t('app.btn.yes') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapActions, mapMutations} from "vuex";
export default {
    name: "deleteDialog",
    data() {
        return {
            dialog: false,
            valuelist: {}
        }
    },
    methods: {
        open(valuelist) {
            this.valuelist = Object.assign({}, valuelist);
            this.dialog = true;
        },
        async applyList() {
            await this.publishValueList(this.valuelist.uuid);
            this.$emit('input', true);
            this.close();
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                Object.assign(this.valuelist, {});
            });
        },
        ...mapMutations({
            onNotification: 'snackbar/onNotification'
        }),
        ...mapActions({
          publishValueList: 'valuelist/publishValueList'
        })
    },
}

</script>

<style scoped>

</style>