<template>
  <v-app-bar id="core-toolbar" dense flat style="background: #eee;" app fixed v-if="$route.name !== 'Login'">
    <v-app-bar-nav-icon class="hidden-lg-and-up" @click.stop="toggleDrawer"></v-app-bar-nav-icon>
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer/>
    <v-toolbar-items floating>
      <div style="width: 200px">
        <core-scheduler/>
        <div v-if="$vuetify.breakpoint.smAndUp" style="position: relative">
          <!--
          <span>{{ $t('app.currentState') }}</span>

          <span style="position: absolute; right: 0;">
            <v-icon color="green darken-2">mdi-check-circle</v-icon>
          </span>
          -->
        </div>
      </div>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>

import {mapMutations} from "vuex";
import CoreScheduler from "./Scheduler";

export default {
  name: 'core-toolbar',

  components: {CoreScheduler},

  data: () => ({
    online_status: "online",
    responsive: false,
    title: null,
    responsiveInput: false
  }),

  watch: {
    '$route' (to) {
      this.title = to.meta.title != null ? this.$t(`${to.meta.title}.title`) : "";
    }
  },

  methods: {
    ...mapMutations("app", ["toggleDrawer"]),
  },
};

</script>
