import userManagementService from "@/service/userManagementService";

const actions = {
    async getUsers({commit}) {
        const response = await userManagementService.getUsersFromApi();
        if (response !== undefined && response.status === 200) {
            const users = response.data.map(user => {
                return {
                    ...user,
                    roles: user.roles.map(role => role.name), // turn list of role objects into list of strings
                }
            });
            commit('setUsers', users)
            return true;
        } else {
            console.error('Error while fetching users from api. Reponse:')
            console.error(response)
            commit('setUsers', [])
            return false;
        }
    },
    async getRoles({commit}) {
        const response = await userManagementService.getRolesFromApi();
        if (response !== undefined && response.status === 200) {
            commit('setRoles', response.data)
            return true;
        } else {
            console.error('Error while fetching roles from api. Reponse:')
            console.error(response)
            commit('setRoles', [])
            return false;
        }
    },
    async createUser({commit}, user) {
        const response = await userManagementService.createUser(user);
        if (response !== undefined && response.status === 201) {
            commit('addUser', {
                ...response.data.user,
                roles: response.data.user.roles.map(role => role.name),
            });
            return response.data.password;
        } else if (response !== undefined && response.status === 409) {
            console.error('ERROR: User already exists')
            return false;
        } else {
            console.error('Error while creating new user. Reponse:')
            console.error(response)
            return false;
        }
    },
    async updateUser({commit}, user) {
        const response = await userManagementService.updateUser(user);
        if (response !== undefined && response.status === 200) {
            commit('replaceUser', {
                ...response.data,
                roles: response.data.roles.map(role => role.name),
            });
            return true;
        } else {
            console.error('Error while updating new user. Reponse:')
            console.error(response)
            return false;
        }
    },
    async changePassword(_, {user, password}) {
        const response = await userManagementService.changePassword(user.id, password);
        if (response !== undefined && response.status === 200) {
            return true;
        } else {
            console.error('Error while changing user password. Reponse:')
            console.error(response)
            return false;
        }
    },
    async deleteUser({commit}, user) {
        const response = await userManagementService.deleteUser(user.id);
        if (response !== undefined && response.status === 204) {
            commit('removeUser', user);
            return true;
        } else {
            console.error('Error while deleting user. Reponse:')
            console.error(response)
            return false;
        }
    },
};

export default actions;
