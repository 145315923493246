const root = '/api';
const v1 = `${root}/v1`;

const apiEndpoints = {
    auth: `${v1}/auth`,
    users: `${v1}/users`,
    version: `${v1}/version`,
    valuelist: `${v1}/valuelist`,
    activity: `${v1}/activity`,
    xsd: `${v1}/xsd`,
    emailAddress: `${v1}/emailAddress`,
};

export default apiEndpoints;
