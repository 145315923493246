export default {
    hasRole: (state) => (requiredRole) => {
        return state.roles.find(role => role === requiredRole) !== undefined;
    },
    hasAnyRole: (state) => (roles) => {
        return state.roles.find(role => roles.includes(role));
    },
    isOffline: (state) => {
        return state.isOffline;
    }
}
